import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  headbox: {
    // background: "linear-gradient(90deg, rgba(181, 22, 50, 0.435) 5.43%, rgba(181, 22, 50, 0.87) 49.67%, rgba(181, 22, 50, 0.435) 95.59%)",
    // filter: " blur(141.379px)",
    // transform: "matrix(1, 0, 0, -1, 0, 0)",
    margin: "0",
    padding: "0",
  },
  content: {
    height: "calc(100vh - 0px)",
    margin: "0",
    padding: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imgbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 47px)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: "url('images/login_bg.png')",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },

  },
  LogoIcon: {
    cursor: "pointer",
    width: "150px",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box className={classes.headbox}>
        <Container maxWidth="md">
          <Box className={classes.content}>
            <Box style={{ width: "100%" }}>{children}</Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
