import axios from "axios";
import Apiconfigs from "../config";
import { toast } from "react-toastify";
export const dataPostHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return error?.response?.data?.responseMessage;
  }
};

export const getWithoutDataHandlerAPI = async (endPoint) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};
export const getDataHandlerAPI = async (endPoint, token) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
      headers: {
        token: token,
      },
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
    }
    if (error.response.statusCode === 404) {
      // window.location.href = "/";
      window.localStorage.removeItem("token");
    }
    return false;
  }
};

export const patchAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const putAPIHandlerQue = async (endPoint, dataToSend, param) => {
  try {
    console.log("params----", param);
    const res = await axios({
      method: "PUT",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: {
        _id: param,
      },
      data: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);

    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return error?.response?.data?.responseMessage;
  }
};

export const putAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "PUT",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);

    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return error?.response?.data?.responseMessage;
  }
};

export const getParticularDataHanndler = async (endPoint, _id) => {
  console.log("ides", _id);
  try {
    const res = await axios({
      method: "GET",
      url: `${Apiconfigs[endPoint]}/${_id}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return error?.response?.data?.responseMessage;
  }
};
export const getParticularObjectDataHanndler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${Apiconfigs[endPoint]}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: _id,
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return error?.response?.data?.responseMessage;
  }
};

export const deleteDataAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return error?.response?.data?.responseMessage;
  }
};

export const postAPIHandlerIdInPath = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${Apiconfigs[endPoint]}/${_id}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const getFilterDataHandlerAPI = async (endPoint, token, dataToSend) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
      headers: {
        token: token,
      },
      params: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};
export const uploadMultipleFile = async (endPoint, dataToSend) => {
  let res, formdata;
  formdata = new FormData();
  Object.values(dataToSend).forEach((res) => {
    formdata.append("uploaded_file", res);
  });
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      data: formdata,
    });
    if (res.data.statusCode === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    // return error?.response?.data?.responseMessage;
  }
};
