import React from "react";
import { makeStyles, Box } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  animateBox: {
    height: "300px",
    width: "300px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    "& img": {
      position: "absolute",
      left: "0px",
      top: "0px",
      maxWidth: "100%",
    },
  },
}));
export default function AnimateLogo() {
  const classes = useStyles();
  return (
    <Box className={classes.animateBox}>
      {/* <LinearProgress height={10} /> */}
      <img
        className={classes.loader}
        src="/images/loader/logo.png"
        alt="loader"
      />
      <img
        className={`${classes.loader} animateLogo`}
        src="/images/loader/1st.png"
        alt="loader"
      />
      <img
        className={`${classes.loader} animateLogo2`}
        src="/images/loader/2nd.png"
        alt="loader"
      />
      <img
        className={`${classes.loader} animateLogo3`}
        src="/images/loader/3rd.png"
        alt="loader"
      />
      <img
        className={`${classes.loader} animateLogo4`}
        src="/images/loader/4th.png"
        alt="loader"
      />
      <img
        className={`${classes.loader} animateLogo5`}
        src="/images/loader/5th.png"
        alt="loader"
      />
      <img
        className={`${classes.loader} animateLogo6`}
        src="/images/loader/6th.png"
        alt="loader"
      />
    </Box>
  );
}
