import React, { useState, useEffect } from "react";
//react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import MoneyIcon from "@material-ui/icons/Money";
import { Link, RouteEndPath } from "react-router-dom";
import { TfiCommentsSmiley } from "react-icons/tfi";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { GiBlackBook, GiStoneBlock } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { FaUserCheck } from "react-icons/fa";
import { SiMarketo } from "react-icons/si";
//icons from react icons
import {
  FiHome,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";
import { RiDatabase2Line } from "react-icons/ri";
import { TiHomeOutline } from "react-icons/ti";
import { BiCog, BiCategory } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { FaList, FaRegHeart, FaRegUser } from "react-icons/fa";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { useHistory } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";

const Sidenav = () => {
  //menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const history = useHistory();
  //custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse
      ? window.sessionStorage.setItem("toggle", false)
      : window.sessionStorage.setItem("toggle", true);
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  useEffect(() => {
    if (window.sessionStorage.getItem("toggle") === "true") {
      setMenuCollapse(true);
    } else {
      setMenuCollapse(false);
    }
  }, [window.sessionStorage.getItem("toggle")]);

  const RouteEndPath = window.location.pathname;

  const logoutHandler = () => {
    history.push("/");
    window.sessionStorage.removeItem("token");
  };

  return (
    <>
      <div id="header">
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="logotext">
              {/* Icon change using menucollapse state */}
              <p>
                {menuCollapse ? (
                  <img
                    class="mobileHide"
                    src="/images/sidelogo.png"
                    width="36px"
                    height="37px"
                    alt="Logo"
                  />
                ) : (
                  <img
                    class="mobileHide"
                    src="/images/DarkLogo.png"
                    width="150"
                    alt="Logo"
                  />
                )}
              </p>
            </div>
            <div className="closemenu" onClick={menuIconClick}>
              {/* changing menu collapse icon on click */}
              {menuCollapse ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem
                component={Link}
                onClick={() => {
                  history.push("/dashboard");
                }}
                active={true}
                icon={
                  <FiHome
                    className={RouteEndPath == "/dashboard" ? "activebox" : ""}
                  />
                }
              >
                <span
                  className={RouteEndPath == "/dashboard" ? "activeText" : ""}
                >
                  Dashboard
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => history.push("/user-management")}
                icon={
                  <HiUserGroup
                    className={
                      RouteEndPath == "/user-management" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath == "/user-management" ? "activeText" : ""
                  }
                >
                  Total Users
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => history.push("/student-management")}
                icon={
                  <FaRegUser
                    className={
                      RouteEndPath == "/student-management" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath == "/student-management" ? "activeText" : ""
                  }
                >
                  {" "}
                  Total Students
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => history.push("/course-management")}
                icon={
                  <GiBlackBook
                    className={
                      RouteEndPath == "/course-management" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath == "/course-management" ? "activeText" : ""
                  }
                >
                  Total Courses
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => history.push("/institute-management")}
                icon={
                  <TiHomeOutline
                    className={
                      RouteEndPath == "/institute-management" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath == "/institute-management" ? "activeText" : ""
                  }
                >
                  Total Institute
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => history.push("/stake-management")}
                icon={
                  <RiDatabase2Line
                    className={
                      RouteEndPath == "/stake-management" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath == "/stake-management" ? "activeText" : ""
                  }
                >
                  Stake Management
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => history.push("/category-management")}
                icon={
                  <BiCategory
                    className={
                      RouteEndPath == "/category-management" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath == "/category-management" ? "activeText" : ""
                  }
                >
                  Category Management
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => history.push("/commision-management")}
                icon={
                  <MoneyIcon
                    className={
                      RouteEndPath == "/commision-management" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath == "/commision-management" ? "activeText" : ""
                  }
                >
                  Commision Management
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => history.push("/redeem-management")}
                icon={
                  <LocalAtmIcon
                    className={
                      RouteEndPath == "/redeem-management" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath == "/redeem-management" ? "activeText" : ""
                  }
                >
                  Redeem Management
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => history.push("/requestedKyc-List")}
                icon={
                  <FaUserCheck
                    className={
                      RouteEndPath == "/requestedKyc-List" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath == "/requestedKyc-List" ? "activeText" : ""
                  }
                >
                  Requested Kyc
                </span>
              </MenuItem>
              {/* <MenuItem
                component={Link}
                onClick={() => history.push("/marketplace")}
                icon={
                  <SiMarketo
                    className={
                      RouteEndPath == "/marketplace" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={RouteEndPath == "/marketplace" ? "activeText" : ""}
                >
                  Marketplace
                </span>
                
              </MenuItem> */}

              <MenuItem
                component={Link}
                onClick={() => history.push("/marketplace-nft")}
                icon={
                  <SiMarketo
                    className={
                      RouteEndPath == "/marketplace-nft" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath == "/marketplace-nft" ? "activeText" : ""
                  }
                >
                  Marketplace
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => history.push("/blo-management")}
                icon={
                  <GiStoneBlock
                    className={
                      RouteEndPath == "/blo-management" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath == "/blo-management" ? "activeText" : ""
                  }
                >
                  Blog Management
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => history.push("/profile")}
                icon={
                  <AiOutlineUser
                    className={RouteEndPath == "/profile" ? "activebox" : ""}
                  />
                }
              >
                <span
                  className={RouteEndPath == "/profile" ? "activeText" : ""}
                >
                  Profile
                </span>
              </MenuItem>
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem icon={<FiLogOut />} onClick={() => setOpenLogout(true)}>
                Logout
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
        <ConfirmationDialog
          title="LOGOUT"
          desc="Are you sure you want to logout?"
          open={openLogout}
          handleClose={() => setOpenLogout(false)}
          logoutHandler={logoutHandler}
        />
      </div>
    </>
  );
};
export default Sidenav;
