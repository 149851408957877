import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout/Index";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/LoginPage/LoginPage")),
  },
  {
    exact: true,
    path: "/forgetpassword",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/ForgotPassword/Forgetpassword")
    ),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/ResetPassword/ResetPassword")
    ),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/VarifyOtp/index")),
  },
  {
    guard: true,
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dasboard")),
  },
  {
    guard: true,
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/UserManagement/UserManagement")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/user-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/UserDetails")),
  },
  {
    guard: true,
    exact: true,
    path: "/student-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StudentManagement")),
  },
  {
    guard: true,
    exact: true,
    path: "/student-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StudentManagement/StudentDetails")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/course-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CourseManagement")),
  },
  {
    guard: true,
    exact: true,
    path: "/course-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/CourseManagement/CourseDetails")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/institute-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/InstituteManagement")),
  },
  {
    guard: true,
    exact: true,
    path: "/category-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CategoryManagemet/index")),
  },
  {
    guard: true,
    exact: true,
    path: "/stake-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StackManagement")),
  },
  {
    guard: true,
    exact: true,
    path: "/stake-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StackManagement/StakemanagementView")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/stake-managementview",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StackManagement/StakemanagementView")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/requestedKyc-List",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/RequestedKyc")),
  },
  {
    guard: true,
    exact: true,
    path: "/blo-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BlogManagement")),
  },
  {
    guard: true,
    exact: true,
    path: "/marketplace",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MarketPlace")),
  },
  {
    guard: true,
    exact: true,
    path: "/marketplace-nft",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MarketPlace/MarketNFt")),
  },
  {
    guard: true,
    exact: true,
    path: "/marketplace-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MarketPlace/MarketplaceDetails")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/blo-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BlogManagement/BlogDetails")),
  },
  {
    guard: true,
    exact: true,
    path: "/addEdit-blog",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BlogManagement/AddEditBlog")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/viewlinks",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SocialManagment/ViewSocialLink")
    ),
  },
  {
    exact: true,
    path: "/privacy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Privacy")),
  },
  {
    exact: true,
    path: "/disclaimer",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Disclaimer")),
  },
  {
    exact: true,
    path: "/terms",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Terms")),
  },
  {
    exact: true,
    path: "/about",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/About")),
  },

  {
    exact: true,
    path: "/contact",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Contact")),
  },

  {
    guard: true,
    exact: true,
    path: "/feedback",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Feedback")),
  },
  {
    guard: true,
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AdminProfile/Profile")),
  },

  {
    guard: true,
    exact: true,
    path: "/social",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/SocialManagment/Index")),
  },
  {
    // guard: true
    exact: true,
    path: "/static",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticManagement/Index")),
  },
  {
    // guard: true
    exact: true,
    path: "/editstatic",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticManagement/EditStaticData")
    ),
  },
  {
    // guard: true
    exact: true,
    path: "/view-contact",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ContactManagement/ViewContact")
    ),
  },
  {
    // guard: true
    exact: true,
    path: "/contact-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ContactManagement/Index")),
  },
  {
    // guard: true
    exact: true,
    path: "/feedback-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/FeedbackManagement/Index")),
  },
  {
    // guard: true,
    exact: true,
    path: "/view-feedback",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/FeedbackManagement/ViewFeedback")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/institute-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/InstituteDetails/InstituteDetails")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/commision-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/CommisionManagement/Commision")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/redeem-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/RedeemManagement/Redeem")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
