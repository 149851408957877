import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    overflow: "hidden",
    backgroundColor: "#12101A",
  },
  mainBody: {
    display: "flex",
    width: "100%",
    minHeight: "100vh",
  },

  wrapper: {
    width: "100%",
    position: "relative",
    paddingTop: 70,
    background: "rgba(0, 0, 0, 0.3)",
    // backdropFilter: "blur(125px)",
    // height: "calc(100vh - 70px)",
  },
  contentContainer: {
    position: "relative",
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    // height: "calc(100% - 95px)",
    overflow: "hidden",
    WebkitOverflowScrolling: "touch",
    padding: "20px 20px 20px 20px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 10px 15px 10px ",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <div className={classes.mainBody}>
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />

        <div className={classes.wrapper}>
          <div className="purpleShade"></div>
          <div className="pinkShade"></div>
          <div className={classes.contentContainer}>
            <div className={classes.content} id="main-scroll">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
