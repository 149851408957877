import { getWeb3Obj } from "src/utils";

export const NetworkContextName = "NETWORK";
export const ACTIVE_NETWORK = 137;
export const RPC_URL = "https://polygon-rpc.com";
export const dataFormat = "DD MMM YYYY hh:mm a ";
export const stakingContractAddress =
  // "0x7753f97e10Fc5db1dCF2dF37f74381F33493C1ba";
  "0xebfb951C6401930880210A6b506Efa15f22473b7";
export const marketPlaceAddress =
  // "0x477287a5b968EB7802d75C2349777F32fCFF0998"; //mainnet-low-price
  "0xF6933b5921fb3070B50Ac868dA5dcb242242514B"; //mainnet-client-price
export const hexaSaleAddress =
  // "0x2e8D211A0d8efaa22c05f8d32686eC6B0aC7E334"; //mainnet-low-price
  "0xb7c88cF36E28FcdAD9733c8f2f5489CE07C35d81"; //mainnet-client-price
export const dateFormat = "DD/MM/YYYY";
export const nativeTokenAddressHexa =
  // "0x78f85b5186c3fd44f1dfc5bf728002423e254ee1"; //mainnet-low-price
  "0xba94d82fe17375bd2202b16d39e60f2fa4b34781"; //mainnet-client-price
export const NetworkDetails = [
  {
    chainId: "0x89",
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
];

export const getWeb3ContractObject = async (
  abi,
  contractAddress,
  RPC_URL = RPC_URL
) => {
  const web3 = await getWeb3Obj(RPC_URL);
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};
export const validAddUrl = (value) => {
  const re =
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  return re.test(value);
};
