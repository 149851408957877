import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import AnimateLogo from "./AnimateLogo";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    zIndex: 2000,
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
       <div className="purpleShade"></div>
        <div className="pinkShade"></div>
      <AnimateLogo />
    </div>
  );
}
