import React, { createContext, useState, useEffect } from "react";
import { injected } from "../connectors";
import axios from "axios";
import { getDataHandlerAPI } from "src/apiConfig/service";
import { calculateTimeLeft } from "../utils/index";
import Apiconfigs from "src/apiConfig/config";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { SUPPORTED_WALLETS } from "src/connectors";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");

  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [isMetaMaskOpen, setIsOpenMetaMaskOpen] = useState(false);
  const [categoryType, setcategoryType] = useState("");
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [getUsdtPrice, setgetUsdtPrice] = useState({});
  const { activate, account, chainId } = useWeb3React();
  const [redeemValue, setRedeemValue] = useState({});
  const [platformEarnings, setPlatformEarnings] = useState({});
  const GetUsdtPriceAPI = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.getUsdtPrice,
        // headers: {
        //   token: token,
        // },
      });
      if (res.data.statusCode === 200) {
        setgetUsdtPrice(res?.data?.result);
        // getDashboardDataApiHandler(token);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.data.statusCode == "404") {
          window.sessionStorage.removeItem("token");
        }
      }
    }
  };
  const getUserProfileDatahandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.adminProfile,
        headers: {
          token: token,
        },
      });
      if (res.data.statusCode === 200) {
        setUserData(res?.data?.result);
        getDashboardDataApiHandler(token);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.data.statusCode == "404") {
          window.sessionStorage.removeItem("token");
        }
      }
    }
  };

  const getDashboardDataApiHandler = async (token) => {
    try {
      setIsLoading(true);
      const res = await axios({
        method: "GET",
        url: Apiconfigs.dashboard,
        headers: {
          token: token,
        },
      });
      if (res.data.statusCode === 200) {
        setDashboardData(res.data.result);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const connectWalletHandler = (data) => {
    try {
      localStorage.setItem("walletName", data?.name);
      const connector = data?.connector;
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          localStorage.removeItem("walletName");
          // activate(connector);
          console.log("error", error);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.error(JSON.stringify(error.message));
    }
  };

  const disconnectWalletHandler = async () => {
    try {
      // deactivate();
      toast.success("You have been disconnected successfully!");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userType");
      window.localStorage.removeItem("walletName");
      window.location = "/commision-management";
    } catch (error) {
      console.log(error);
    }
  };
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.warn(error.message);
    }
  };
  const getRedeemCommissionHandler = async () => {
    try {
      const response = await getDataHandlerAPI("getCommission", {});
      if (response) {
        setRedeemValue(response.data.result);
      }
    } catch (error) {}
  };
  const getPlatformEarningAPIHandler = async () => {
    try {
      const response = await getDataHandlerAPI("commissionsAmount");
      if (response) {
        // console.log("responsePleaformEarning---", response);
        setPlatformEarnings(response.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    GetUsdtPriceAPI();
    if (window.sessionStorage.getItem("token")) {
      getUserProfileDatahandler(window.sessionStorage.getItem("token"));
      // getDashboardDataApiHandler(window.sessionStorage.getItem("token"));
    }
  }, []);
  useEffect(() => {
    if (account) {
      setIsOpenMetaMaskOpen(false);
    }
  }, [account]);
  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter((data) => {
        return data?.data?.name == localStorage.getItem("walletName");
      });
      if (selectectWalletDetails.length > 0) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    }
  }, [localStorage.getItem("walletName")]);
  useEffect(() => {
    getRedeemCommissionHandler();
    getPlatformEarningAPIHandler();
  }, []);
  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);
  let data = {
    userLoggedIn: isLogin,
    userData,
    platformEarnings,
    getUsdtPrice,
    isMetaMaskOpen,
    dashboardData,
    isLoading,
    categoryType,
    setcategoryType,
    timeLeft,
    setTimeLeft,
    setEndtime,
    redeemValue,
    setIsOpenMetaMaskOpen: (data) => setIsOpenMetaMaskOpen(data),
    connectWallet: (data) => connectWalletHandler(data),
    disconnectWallet: () => disconnectWalletHandler(),
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    getUserProfileDatahandler: (data) => getUserProfileDatahandler(data),
    GetUsdtPriceAPI: (data) => GetUsdtPriceAPI(data),
    getRedeemCommissionHandler: (data) => getRedeemCommissionHandler(data),

    isLoading,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
